import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { Link } from 'react-router-dom';

if (typeof window !== 'undefined') {
    import('pure-react-carousel/dist/react-carousel.es.css');
}

import css from './CommunityCarousel.module.css';
import backarrow from './carouselImages/backarrow.svg';
import nextarrow from './carouselImages/nextarrow.svg';
import { NamedLink } from '../../../components';
import { createSlug } from '../../../util/urlHelpers';

export default class CommunityCarousel extends React.Component {
    render() {
        const { totalCommunityMember } = this.props;
        return (
            <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={100}
                totalSlides={12}
                visibleSlides={3}
                step={1}
                isPlaying={true}
                interval={5000}
                infinite={true}
                className={css.provider}
            >
                <ButtonBack className={css.btn}><img src={backarrow} alt={'back arrow'} style={{ width: '4vw', height: '4vw' }} /></ButtonBack>                <div className={css.sliderContainer}>
                    <Slider className={css.slider}>
                        {totalCommunityMember && totalCommunityMember.length && totalCommunityMember?.map((seller, index) => {
                            const { title = '' } = seller.attributes
                            const slug = createSlug(title);
                            return (
                                <Slide index={index} key={index} className={css.slideborder}>
                                    <div className={css.slide}>
                                        <NamedLink name="ProfilePage" params={{ id: seller?.id.uuid, slug }}>
                                            <span className={css.profileLink}>VIEW STORE</span>
                                            <img src={seller?.attributes?.profile?.publicData?.
                                                store_owner_photo
                                            } alt={"seller Image"} className={css.img} />
                                            <div className={css.label}>
                                                <h3>Small Business</h3>
                                                <p>Owned by {seller?.attributes?.profile?.displayName}</p>
                                            </div>
                                        </NamedLink>
                                    </div>
                                </Slide>)
                        })}
                    </Slider>
                </div>
                <ButtonNext className={css.btn}><img src={nextarrow} alt={'next arrow'} style={{ width: '4vw', height: '4vw' }} /></ButtonNext>            </CarouselProvider>
        );
    }
}
